<template>
  <div class="inner-link-api-content">
    <ApiStepsVue :link="baseExternalUrl+link" :success="success" />
    <div class="toggle">
      <div
        class="title-toggle"
        :class="{ 'title-toggle-active': id == 1 }"
        @click="check(1)"
      >
        <p>
          {{ $t("apiCode") }}
        </p>
        <i class="icon-toggle" :class="id == 1 ? 'active-icon-toggle' : ''">
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="#31006F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </i>
      </div>
      <div :class="id == 1 ? 'active-content-toggle' : 'content-toggle'">
        <div class="link-api-input link-api-input-code">
          <div style="position: relative">
            <p class="textarea" style="white-space: pre-line" dir="auto">{{ apiCode }}</p>
            <span @click="copyText('copyText4')">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 1H12.6C14.8402 1 15.9603 1 16.816 1.43597C17.5686 1.81947 18.1805 2.43139 18.564 3.18404C19 4.03969 19 5.15979 19 7.4V14.5M4.2 19H12.3C13.4201 19 13.9802 19 14.408 18.782C14.7843 18.5903 15.0903 18.2843 15.282 17.908C15.5 17.4802 15.5 16.9201 15.5 15.8V7.7C15.5 6.57989 15.5 6.01984 15.282 5.59202C15.0903 5.21569 14.7843 4.90973 14.408 4.71799C13.9802 4.5 13.4201 4.5 12.3 4.5H4.2C3.0799 4.5 2.51984 4.5 2.09202 4.71799C1.71569 4.90973 1.40973 5.21569 1.21799 5.59202C1 6.01984 1 6.57989 1 7.7V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.0799 19 4.2 19Z"
                  stroke="#31006F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <input ref="copyText4" type="hidden" :value="apiCode" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showGovernmentApi">
      <div class="toggle">
        <div
          class="title-toggle"
          :class="{ 'title-toggle-active': id == 5 }"
          @click="check(5)"
        >
          <p>المناطق</p>
          <i class="icon-toggle" :class="id == 5 ? 'active-icon-toggle' : ''">
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L13 1"
                stroke="#31006F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <div
          :class="
            id == 5
              ? 'active-content-toggle content-toggle-api'
              : 'content-toggle'
          "
        >
          <ApiStepsVue :link="baseExternalUrl+linkGetArea" :success="successGetArea" />
          <div class="link-api-input link-api-input-code">
            <h6>{{ $t("apiCode") }}</h6>
            <div style="position: relative">
              <p class="textarea" style="white-space: pre-line">{{ apiCodeGetArea }}
              </p>
              <span @click="copyText('textArea')">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 1H12.6C14.8402 1 15.9603 1 16.816 1.43597C17.5686 1.81947 18.1805 2.43139 18.564 3.18404C19 4.03969 19 5.15979 19 7.4V14.5M4.2 19H12.3C13.4201 19 13.9802 19 14.408 18.782C14.7843 18.5903 15.0903 18.2843 15.282 17.908C15.5 17.4802 15.5 16.9201 15.5 15.8V7.7C15.5 6.57989 15.5 6.01984 15.282 5.59202C15.0903 5.21569 14.7843 4.90973 14.408 4.71799C13.9802 4.5 13.4201 4.5 12.3 4.5H4.2C3.0799 4.5 2.51984 4.5 2.09202 4.71799C1.71569 4.90973 1.40973 5.21569 1.21799 5.59202C1 6.01984 1 6.57989 1 7.7V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.0799 19 4.2 19Z"
                    stroke="#31006F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input ref="textArea" type="hidden" :value="apiCodeGetArea" />
            </div>
          </div>
        </div>
      </div>
      <div class="toggle">
        <div
          class="title-toggle"
          :class="{ 'title-toggle-active': id == 4 }"
          @click="check(4)"
        >
          <p>المحافظات</p>
          <i class="icon-toggle" :class="id == 4 ? 'active-icon-toggle' : ''">
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L13 1"
                stroke="#31006F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <div
          :class="
            id == 4
              ? 'active-content-toggle content-toggle-api'
              : 'content-toggle'
          "
        >
          <ApiStepsVue
            :link="baseExternalUrl+linkGetGovernments"
            :success="successGetGovernments"
          />
          <div class="link-api-input link-api-input-code">
            <h6>{{ $t("apiCode") }}</h6>
            <div style="position: relative">
              <p class="textarea" style="white-space: pre-line">{{ apiCodeGetGovernments }}
              </p>
              <span @click="copyText('textGovernment')">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 1H12.6C14.8402 1 15.9603 1 16.816 1.43597C17.5686 1.81947 18.1805 2.43139 18.564 3.18404C19 4.03969 19 5.15979 19 7.4V14.5M4.2 19H12.3C13.4201 19 13.9802 19 14.408 18.782C14.7843 18.5903 15.0903 18.2843 15.282 17.908C15.5 17.4802 15.5 16.9201 15.5 15.8V7.7C15.5 6.57989 15.5 6.01984 15.282 5.59202C15.0903 5.21569 14.7843 4.90973 14.408 4.71799C13.9802 4.5 13.4201 4.5 12.3 4.5H4.2C3.0799 4.5 2.51984 4.5 2.09202 4.71799C1.71569 4.90973 1.40973 5.21569 1.21799 5.59202C1 6.01984 1 6.57989 1 7.7V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.0799 19 4.2 19Z"
                    stroke="#31006F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                ref="textGovernment"
                type="hidden"
                :value="apiCodeGetGovernments"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="toggle">
        <div
          class="title-toggle"
          :class="{ 'title-toggle-active': id == 3 }"
          @click="check(3)"
        >
          <p>اكواد المحافظات</p>
          <i class="icon-toggle" :class="id == 3 ? 'active-icon-toggle' : ''">
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L13 1"
                stroke="#31006F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <div :class="id == 3 ? 'active-content-toggle' : 'content-toggle'">
          <div class="link-api-input-tabel">
            <table class="w-full">
              <thead>
                <tr>
                  <th>City</th>
                  <th>API Key</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tr, index) in governments" :key="index">
                  <td>{{ tr.name }}</td>
                  <td>{{ tr.id }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="toggle">
      <div
        class="title-toggle"
        :class="{ 'title-toggle-active': id == 2 }"
        @click="check(2)"
      >
        <p>القيم المطلوبة للربط</p>
        <i class="icon-toggle" :class="id == 2 ? 'active-icon-toggle' : ''">
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="#31006F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </i>
      </div>
      <div :class="id == 2 ? 'active-content-toggle' : 'content-toggle'">
        <div class="link-api-input-tabel">
          <table class="w-full">
            <thead>
              <tr>
                <th>API Key</th>
                <th>API Value</th>
                <th>Descryption</th>
                <th>Mandatory</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tr, index) in data" :key="index">
                <td>{{ tr.key }}</td>
                <td>{{ tr.value }}</td>
                <td>{{ tr.descryption }}</td>
                <td><span :class="tr.class">{{ tr.mandatory }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import copyText from "@/mixins/helper.js";
import ApiStepsVue from "./ApiSteps.vue";
import { mapState } from "vuex";

export default {
  name: "link-api",
  mixins: [copyText],
  data() {
    return {
      baseExternalUrl: process.env.VUE_APP_BASE_EXTERNAL_URL,
      typeCode: 0,
      cities: [
        { city: "القاهرة", cityName: "القاهرة" },
        { city: "الجيزة", cityName: "الجيزة" },
        { city: "الشرقية", cityName: "الشرقية" },
        { city: "الدقهلية", cityName: "الدقهلية" },
        { city: "البحيرة", cityName: "البحيرة" },
        { city: "المنيا", cityName: "المنيا" },
        { city: "القليوبية", cityName: "القليوبية" },
        { city: "الإسكندرية", cityName: "الإسكندرية" },
        { city: "الغربية", cityName: "الغربية" },
        { city: "سوهاج", cityName: "سوهاج" },
        { city: "أسيوط", cityName: "أسيوط" },
        { city: "المنوفية", cityName: "المنوفية" },
        { city: "كفر الشيخ", cityName: "كفر الشيخ" },
        { city: "الفيوم", cityName: "الفيوم" },
        { city: "قنا", cityName: "قنا" },
        { city: "بني سويف", cityName: "بني سويف" },
        { city: "دمياط", cityName: "دمياط" },
        { city: "الأقصر", cityName: "الأقصر" },
        { city: "بورسعيد", cityName: "بورسعيد" },
        { city: "السويس", cityName: "السويس" },
        { city: "مطروح", cityName: "مطروح" },
        { city: "شمال سيناء", cityName: "شمال سيناء" },
        { city: "البحر الأحمر", cityName: "البحر الأحمر" },
        { city: "الوادي الجديد", cityName: "الوادي الجديد" },
        { city: "جنوب سيناء", cityName: "جنوب سيناء" },
        { city: "أطراف القاهرة والجيزة", cityName: "أطراف القاهرة والجيزة" },
      ],
      id: null,
      linkGetGovernments: "get-government",
      successGetGovernments: `{"success": 1, "error_msg": "", "feed": [{"id": 1, "name": "القاهرة"}], "errors": []`,
      apiCodeGetGovernments: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }"}`,
      dataGetGovernments: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
      ],
      linkGetArea: "get-area/government_id",
      successGetArea: `{"success": 1, "error_msg": "", "feed": [{"id": 1, "name": "السيدة زنيب", "status": 1}], "errors": []`,
      apiCodeGetArea: `{"authentication_key": "${
        JSON.parse(localStorage.getItem("userData")).apiToken
      }"}`,
      dataGetArea: [
        {
          key: "authentication_key",
          value: JSON.parse(localStorage.getItem("userData")).apiToken,
          descryption: "مفتاح رئيسي",
          mandatory: "نعم",
          class: "yes",
        },
        {
          key: "government_id",
          value: "1",
          descryption: "id المحافظة",
          mandatory: "نعم",
          class: "yes",
        },
      ],
    };
  },
  computed: {
    ...mapState("externalApi", ["governments"]),
  },
  components: {
    ApiStepsVue,
  },
  props: {
    link: {
      type: String,
    },
    success: {
      type: String,
    },
    apiCode: {
      type: String,
    },
    data: {
      type: Array,
    },
    showGovernmentApi: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    check(id) {
      if (this.id === id) {
        this.id = null;
      } else {
        this.id = id;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_linkApi.scss";
</style>
