<template>
  <div>
    <div class="link-api-input flex items-center">
      <h6 class="link-api-step">
        <span>1</span>
      </h6>
      <div class="inner-link-api-input">
        <label>{{ $t("sendMeLink") }} :</label>
        <div style="position: relative">
          <p>{{ link }}</p>
          <span @click="copyText('copyText1')">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 1H12.6C14.8402 1 15.9603 1 16.816 1.43597C17.5686 1.81947 18.1805 2.43139 18.564 3.18404C19 4.03969 19 5.15979 19 7.4V14.5M4.2 19H12.3C13.4201 19 13.9802 19 14.408 18.782C14.7843 18.5903 15.0903 18.2843 15.282 17.908C15.5 17.4802 15.5 16.9201 15.5 15.8V7.7C15.5 6.57989 15.5 6.01984 15.282 5.59202C15.0903 5.21569 14.7843 4.90973 14.408 4.71799C13.9802 4.5 13.4201 4.5 12.3 4.5H4.2C3.0799 4.5 2.51984 4.5 2.09202 4.71799C1.71569 4.90973 1.40973 5.21569 1.21799 5.59202C1 6.01984 1 6.57989 1 7.7V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.0799 19 4.2 19Z"
                stroke="#31006F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <input
            ref="copyText1"
            type="hidden"
            style="cursor: default"
            :value="link"
          />
        </div>
      </div>
    </div>
    <div class="link-api-input flex items-center">
      <h6 class="link-api-step">
        <span>2</span>
      </h6>
      <div class="inner-link-api-input">
        <label>أدخل التالي في <strong>header</strong> :</label>
        <div class="flex justify-between">
          <div class="link-api-input-2">
            <p>Accept:application/json</p>
            <span @click="copyText('copyText2')">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 1H12.6C14.8402 1 15.9603 1 16.816 1.43597C17.5686 1.81947 18.1805 2.43139 18.564 3.18404C19 4.03969 19 5.15979 19 7.4V14.5M4.2 19H12.3C13.4201 19 13.9802 19 14.408 18.782C14.7843 18.5903 15.0903 18.2843 15.282 17.908C15.5 17.4802 15.5 16.9201 15.5 15.8V7.7C15.5 6.57989 15.5 6.01984 15.282 5.59202C15.0903 5.21569 14.7843 4.90973 14.408 4.71799C13.9802 4.5 13.4201 4.5 12.3 4.5H4.2C3.0799 4.5 2.51984 4.5 2.09202 4.71799C1.71569 4.90973 1.40973 5.21569 1.21799 5.59202C1 6.01984 1 6.57989 1 7.7V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.0799 19 4.2 19Z"
                  stroke="#31006F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <input
              ref="copyText2"
              type="hidden"
              style="cursor: default"
              value="Accept:application/json"
            />
          </div>
          <div class="link-api-input-2">
            <p>Content-Type:application/json</p>
            <span @click="copyText('copyText3')">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 1H12.6C14.8402 1 15.9603 1 16.816 1.43597C17.5686 1.81947 18.1805 2.43139 18.564 3.18404C19 4.03969 19 5.15979 19 7.4V14.5M4.2 19H12.3C13.4201 19 13.9802 19 14.408 18.782C14.7843 18.5903 15.0903 18.2843 15.282 17.908C15.5 17.4802 15.5 16.9201 15.5 15.8V7.7C15.5 6.57989 15.5 6.01984 15.282 5.59202C15.0903 5.21569 14.7843 4.90973 14.408 4.71799C13.9802 4.5 13.4201 4.5 12.3 4.5H4.2C3.0799 4.5 2.51984 4.5 2.09202 4.71799C1.71569 4.90973 1.40973 5.21569 1.21799 5.59202C1 6.01984 1 6.57989 1 7.7V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.0799 19 4.2 19Z"
                  stroke="#31006F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <input
              ref="copyText3"
              type="hidden"
              style="cursor: default"
              value="Content-Type:application/json"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="link-api-input link-api-input-success">
      <h4>{{ $t("replayCaseSuccess") }}</h4>
      <p>
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="30" height="30" rx="8" fill="#3CB95E" />
          <path
            d="M25 14.0857V15.0057C24.9988 17.1621 24.3005 19.2604 23.0093 20.9875C21.7182 22.7147 19.9033 23.9782 17.8354 24.5896C15.7674 25.201 13.5573 25.1276 11.5345 24.3803C9.51168 23.633 7.78465 22.2518 6.61096 20.4428C5.43727 18.6338 4.87979 16.4938 5.02168 14.342C5.16356 12.1903 5.99721 10.1421 7.39828 8.5028C8.79935 6.86354 10.6928 5.72111 12.7962 5.24587C14.8996 4.77063 17.1003 4.98806 19.07 5.86572M25 7L15 17.01L12 14.01"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          /></svg
        >{{ success }}
      </p>
    </div>
  </div>
</template>

<script>
import copyText from "@/mixins/helper.js";
export default {
  mixins: [copyText],
  props: {
    link: {
      type: String,
    },
    success: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_linkApi.scss";
</style>
