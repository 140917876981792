<template>
    <div>
        <vs-alert v-if="this.client.api_url" :color="colorAlert" class="mb-5 cancel-api" style="background-color: #28c76f2b;">
            <div style="display: flex; justify-content: space-between;">
                <span class="ml-2" style="color: #28c76f; display: flex; align-items: center;">
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13.056" height="13.056" viewBox="0 0 13.056 13.056">
                        <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M13.619,7.091A6.528,6.528,0,1,1,7.091.563,6.528,6.528,0,0,1,13.619,7.091ZM6.335,10.547,11.179,5.7a.421.421,0,0,0,0-.6l-.6-.6a.421.421,0,0,0-.6,0l-3.95,3.95L4.193,6.618a.421.421,0,0,0-.6,0l-.6.6a.421.421,0,0,0,0,.6L5.74,10.547a.421.421,0,0,0,.6,0Z" transform="translate(-0.563 -0.563)" fill="#28c76f"/>
                    </svg>
                    تم الربط مسبقا مع سيستم تربو
                </span>
                <span style="color: #000; text-decoration: underline; cursor: pointer; margin-left: 12px;" @click="cancelApi()">إلغاء الربط</span>
            </div>
        </vs-alert>

        <div class="instant-link-api">
            <vs-alert :active="success" :color="colorAlert" class="mb-5" :icon="iconAlert" >
                <span>{{error_msg ? error_msg : "تم ارسال البيانات بنجاح"}}</span>
            </vs-alert>
            <div class="header">
                <h3 style="display: flex; align-items: center;">
                    <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="15.314" viewBox="0 0 16 15.314">
                        <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M8.583.532,6.63,4.491l-4.369.637a.957.957,0,0,0-.529,1.633l3.161,3.08-.748,4.351A.956.956,0,0,0,5.533,15.2l3.909-2.055L13.35,15.2a.957.957,0,0,0,1.388-1.008L13.99,9.842l3.161-3.08a.957.957,0,0,0-.529-1.633l-4.369-.637L10.3.532a.958.958,0,0,0-1.717,0Z" transform="translate(-1.441 0.001)" fill="#ff9f43"/>
                    </svg>مهم : للربط و التحديث الفوري مع تربو
                </h3>
                <p>يمكنك عن طريق هذا الربط إرسال واستقبال طرودك بسهولة و التحديث الفوري للحالات الخاصة كل طرد وبشكل لحظي بسهولة وبدون الحاجة للتحديث بنفسك.</p>
            </div>
            <div class="body">
                <h3 style="display: flex; align-items: center;">
                    ادخل البيانات المطلوبة :
                    <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <path id="Icon_awesome-info-circle" data-name="Icon awesome-info-circle" d="M6.563.562a6,6,0,1,0,6,6A6,6,0,0,0,6.563.562Zm0,2.661A1.016,1.016,0,1,1,5.546,4.24,1.016,1.016,0,0,1,6.563,3.224ZM7.917,9.369a.29.29,0,0,1-.29.29H5.5a.29.29,0,0,1-.29-.29V8.788A.29.29,0,0,1,5.5,8.5h.29V6.95H5.5a.29.29,0,0,1-.29-.29V6.079a.29.29,0,0,1,.29-.29H7.046a.29.29,0,0,1,.29.29V8.5h.29a.29.29,0,0,1,.29.29Z" transform="translate(-0.563 -0.563)" fill="#6e6b7b"/>
                    </svg>
                </h3>
                <div class="inputs">
                    <div class="input">
                        <label>رابط الAPI الخاص بيك : <span class="font-bold text-danger">*</span></label>
                        <input class="font-bold text-black" v-model="dataCreation.apiUrl" type="text" placeholder="https://turbo-eg.com">
                        <span class="text-danger font-bold" style="font-size: 12px;" v-if="api_errors.api_url">{{ api_errors.api_url[0] }}</span>
                    </div>
                    <div class="input">
                        <label>الTOKEN الخاص بيك : <span class="font-bold text-danger small text-xs">Optional: Bearer Token</span></label>
                        <input class="font-bold text-black" v-model="dataCreation.apiToken" :type="hideToken ? 'password' : 'text'" placeholder="0xE954c2d9Ff2a4D260Dcd32386B1F9Fc8135D2522">
                        <svg @click="hideToken =! hideToken" xmlns="http://www.w3.org/2000/svg" width="19.195" height="19.195" viewBox="0 0 19.195 19.195">
                            <g id="Icon_feather-eye-off" data-name="Icon feather-eye-off" transform="translate(-0.439 -0.439)">
                                <path id="Path_2176" data-name="Path 2176" d="M14.647,16.819a7.815,7.815,0,0,1-4.61,1.6C4.6,18.417,1.5,12.209,1.5,12.209A14.319,14.319,0,0,1,5.427,7.6m2.98-1.412A7.078,7.078,0,0,1,10.037,6c5.433,0,8.537,6.209,8.537,6.209A14.357,14.357,0,0,1,16.9,14.684m-5.215-.83a2.328,2.328,0,1,1-3.291-3.291" transform="translate(0 -2.172)" fill="none" stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                <path id="Path_2177" data-name="Path 2177" d="M1.5,1.5,18.574,18.574" transform="translate(0 0)" fill="none" stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="footer mb-6">
                <button @click="update()" :disabled="loading || !validation" :style="[!validation ? {'backgroundColor': '#B9B9C3', 'cursor': 'default'} : {'opacity': 1}]">{{loading ? "جاري الارسال" : "ارسال"}}</button>
            </div>
            <div class="link-api-input-tabel">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th>API Key</th>
                            <th>API Value</th>
                            <th>Description</th>
                            <th>Mandatory</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tr, index) in data" :key="index">
                            <td>{{tr.key}}</td>
                            <td>{{tr.value}}</td>
                            <td>{{tr.descryption}}</td>
                            <td><span :class="tr.class">{{tr.mandatory}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: "instantLinkApi",
    computed: {
        ...mapGetters('userData', ['client', 'api_errors', 'error_msg', 'success_api', 'updated_info']),
        validation() {
            if (this.dataCreation.apiUrl != ""){
                return true
            }else {
                return false
            }
        }
    },
    data() {
        return {
            dataCreation: {
                apiUrl: undefined,
                apiToken: undefined
            },
            loading: false,
            success: false,
            colorAlert: undefined,
            iconAlert: undefined,
            data: [
                {key: 'order_number', value: 751123, descryption: 'كود الطرد', mandatory: 'نعم', class: 'yes'},
                {key: 'status', value: '5', descryption: 'حالة الطرد', mandatory: 'نعم', class: 'yes'},
                {key: 'order_price', value: '7525', descryption: 'المبلغ المطلوب تحصيله', mandatory: 'نعم', class: 'yes'},
                {key: 'order_type', value: '2', descryption: 'نوع الطرد', mandatory: 'نعم', class: 'yes'},
                {key: 'return_reason', value: 'كتابة سبب الارتجاع (مثال: العميل رفض الاستلام)', descryption: 'سبب الارتجاع', mandatory: 'نعم', class: 'yes'},
                {key: 'delay_reason', value: 'كتابة سبب التأجيل (مثال: موبايل العميل مغلق)', descryption: 'سبب التأجيل', mandatory: 'نعم', class: 'yes'},
                {key: 'mission_code', value: '123456', descryption: 'كود المهمة', mandatory: 'نعم', class: 'yes'},
                {key: 'is_order', value: '0', descryption: 'حالة استلام الطرد مع التحصيل', mandatory: 'نعم', class: 'yes'},
                {key: 'remote_order_id', value: '11223344', descryption: 'كود الطرد من سيستم العميل', mandatory: 'نعم', class: 'yes'},
                {key: 'return_status', value: '0', descryption: 'حالة المرتجع للطرد', mandatory: 'نعم', class: 'yes'},
                {key: 'captain_name', value: 'محمد احمد', descryption: 'إسم الكابتن', mandatory: 'نعم', class: 'yes'},
                {key: 'captain_number1', value: '01000000000', descryption: 'رقم الكابتن الاول', mandatory: 'نعم', class: 'yes'},
                {key: 'captain_number2', value: '01100000000', descryption: 'رقم الكابتن الثانى', mandatory: 'نعم', class: 'yes'},
            ],
            hideToken: true,
        }
    },
    methods: {
        ...mapActions('userData', ['updateApi', "deleteClientApi"]),
        async update() {
            let alertTimeout = setTimeout(() => {
                this.success = false
                window.location.reload()
            }, 3000);
            this.loading = true
            await this.updateApi(this.dataCreation)
            this.dataCreation.apiUrl = this.updated_info.api_url
            this.dataCreation.apiToken = this.updated_info.api_token
            if(this.success_api) {
                this.success = true
                this.colorAlert = "warning"
                this.iconAlert = "done_all"
                alertTimeout
            }else {
                this.success = true
                this.colorAlert = "danger"
                this.iconAlert = "new_releases"
                alertTimeout
            }
            this.loading = false
        },
        async cancelApi() {
            await this.deleteClientApi()
            window.location.reload()
        }
    },
    watch: {
        'client.api_url': {
            handler(newVal) {
                this.dataCreation.apiUrl = newVal
            },
            deep: true
        },
        'client.api_token': {
            handler(newVal) {
                this.dataCreation.apiToken = newVal
            },
        }
    },
    mounted(){
        this.dataCreation.apiUrl = this.client.api_url
        this.dataCreation.apiToken = this.client.api_token
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_linkApi.scss';
@import '@/assets/scss/vuexy/_variables.scss';
.instant-link-api {
    border-radius: 6px;
    margin-bottom: 12px;
    // padding: 13px;
    .header {
        margin-bottom: 24px;
        h3 {
            font-size: 16px;
            line-height: 21px;
            font-weight: bold;
            margin-bottom: 6px;
        }
        p {
            font-size: 14px;
            line-height: 21px;
            color: #6E6B7B;
            font-weight: 600;
        }
    }
    .body {
        margin-bottom: 12px;
        h3 {
            font-size: 14px;
            line-height: 21px;
            color: #6E6B7B;
            margin-bottom: 12px;
            font-weight: bold;
        }
        .inputs {
            display: flex;
            gap: 12px;
            justify-content: space-between;
            flex-wrap: wrap;
            .input {
                position: relative;
                width: 49%;
                svg {
                    position: absolute;
                    right: 12px;
                    top: 37px;
                    cursor: pointer;
                }
                label {
                    display: block;
                    color: #6E6B7B;
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 6px;
                    font-weight: 600;
                }
                input {
                    border: solid 1px #B9B9C3;
                    border-radius: 6px;
                    background-color: #fff;
                    padding: 8px 12px;
                    font-size: 12px;
                    line-height: 21px;
                    width: 100%;
                    color: #B9B9C3;
                    &::placeholder {
                        color: #B9B9C3;
                    }
                }
            }
        }
    }
    .footer {
        text-align: right;
        button {
            border: none;
            border-radius: 6px;
            background-color: $mainColor1;
            color: #fff;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 21px;
            height: 38px;
            cursor: pointer;
            width: 109px;
        }
    }
}
.cancel-api {
        height: fit-content !important;
    }
</style>
